<template>
  <section class="card">
    <div class="card-header">
      <div class="utils__title">
        <strong>Dopravy - Upravit <span class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
      </div>
      <action-tools :router-options="routerOptions"></action-tools>
    </div>
    <div class="card-body">
      <div class="table-operations">
        <language-tab></language-tab>
      </div>
      <a-form :form="form" @submit="handleSubmit">
        <h4 class="text-black mt-4 mb-3"><strong>Nastavitelné podle jazyka</strong></h4>

        <a-form-item
          label="Název"
          :validate-status="error('dhe_name') ? 'error' : ''"
          :help="error('dhe_name') || ''"
        >
          <a-input placeholder="Název" v-decorator="['dhe_name', {rules: [{max: 80, message: 'Název nemůže být delší než 80 znaků!'}, {required: true, message: 'Název musí být vyplněn!'}]}]"/>
        </a-form-item>

        <h4 class="text-black mt-4 mb-3"><strong>Ostatní</strong></h4>

        <a-form-item
          :validate-status="error('heureka_id') ? 'error' : ''"
          :help="error('heureka_id') || ''"
        >
          <span slot="label">
            Doprava heureky
          </span>
          <a-auto-complete
            :dataSource="listHeureka.map(x => { return { value: x.id.toString(), text: x.description } })"
            placeholder="Doprava heureky"
            v-decorator="['heureka_id', {rules: [{required: true, message: 'Doprava heureky musí být vyplněna!'}]}]"
          />
        </a-form-item>
        <a-form-item
          :validate-status="error('zbozi_id') ? 'error' : ''"
          :help="error('zbozi_id') || ''"
        >
          <span slot="label">
            Doprava zboží
          </span>
          <a-auto-complete
            :dataSource="listZbozi.map(x => { return { value: x.id.toString(), text: x.description } })"
            placeholder="Doprava zboží"
            v-decorator="['zbozi_id', {rules: [{required: true, message: 'Doprava zboží musí být vyplněna!'}]}]"
          />
        </a-form-item>

        <a-form-item
          label="Zobrazit od celkové váhy objednávky [g]"
          :validate-status="error('dly_show_from_weight') ? 'error' : ''"
          :help="error('dly_show_from_weight') || ''"
        >
          <a-input-number
            :min="0"
            :step="1"
            :precision="0"
            style="width: 100%;"
            v-decorator="[`dly_show_from_weight`, { initialValue: 0, rules: [{ required: true, message: 'Zobrazit od celkové váhy [g] objednávky musí být vyplněn!' }]}]"
          />
        </a-form-item>

        <a-form-item
          label="Zobrazit od celkové ceny objednávky [Kč]"
          :validate-status="error('dly_show_from_total') ? 'error' : ''"
          :help="error('dly_show_from_total') || ''"
        >
          <a-input-number
            :min="0.00"
            :step="0.01"
            style="width: 100%;"
            v-decorator="[`dly_show_from_total`, { initialValue: 0, rules: [{ required: true, message: 'Zobrazit od celkové ceny objednávky [Kč] musí být vyplněn!' }]}]"
          />
        </a-form-item>

        <a-form-item label="Sčítat cenu?">
          <a-checkbox v-decorator="['dhy_is_sum_weight_price', { valuePropName: 'checked', rules: [{ required: true, message: 'Sčítat cenu musí být vyplněn!' }] }]"/>
        </a-form-item>

        <a-form-item label="Výdejní místo?">
          <a-checkbox v-decorator="['is_pickup', { valuePropName: 'checked', rules: [{ required: true, message: 'Výdejní místo musí být vyplněno!' }] }]"/>
        </a-form-item>

        <a-form-item label="Aktivní?">
          <a-checkbox v-decorator="['dly_is_active', { valuePropName: 'checked', rules: [{ required: true, message: 'Aktivní musí být vyplněno!' }] }]"/>
        </a-form-item>

        <a-form-item
          label="Vyber platby"
          :validate-status="error('payments') ? 'error' : ''"
          :help="error('payments') || ''"
        >
          <a-select placeholder="-- Vyber platby --" mode="multiple" :loading="item.payments.loading" :options="payments.map(x => { return { value: x.pmt_id, label: x.current_history.languages.length > 0 ? '# ' + x.pmt_id + ' | ' + x.current_history.languages[0].phe_name : '# ' + x.pmt_id + ' | není nastaveno' } })" v-decorator="['payments', {rules: [{required: true, message: 'Platby musí být vyplněn!'}]}]"></a-select>
        </a-form-item>

        <a-form-item
          v-for="(k, index) in form.getFieldValue('items')"
          :key="index"
          :label="index === 0 ? 'Váhové rozmezí' : ''"
          :required="true"
        >
          <a-row>
            <a-col :offset="1" :span="21">
              <a-form-item
                label="Minimální váha [g]"
                :validate-status="error(`dwy_weight_min[${index}]`) ? 'error' : ''"
                :help="error(`dwy_weight_min[${index}]`) || ''"
              >
                <a-input-number
                  :min="0"
                  :step="1"
                  :precision="0"
                  style="width: 100%;"
                  v-decorator="[`dwy_weight_min[${index}]`, { initialValue: 0, rules: [{ required: true, message: 'Minimální váha [g] musí být vyplněná!' }]}]"
                />
              </a-form-item>

              <a-form-item
                label="Maximální váha [g]"
                :validate-status="error(`dwy_weight_max[${index}]`) ? 'error' : ''"
                :help="error(`dwy_weight_max[${index}]`) || ''"
              >
                <a-input-number
                  :min="0"
                  :step="1"
                  :precision="0"
                  style="width: 100%;"
                  v-decorator="[`dwy_weight_max[${index}]`, { initialValue: undefined}]"
                />
              </a-form-item>

              <a-form-item
                label="Cena [Kč]"
                :validate-status="error(`dwy_price[${index}]`) ? 'error' : ''"
                :help="error(`dwy_price[${index}]`) || ''"
              >
                <a-input-number
                  :min="0.00"
                  :step="0.01"
                  style="width: 100%;"
                  v-decorator="[`dwy_price[${index}]`, { initialValue: 0, rules: [{ required: true, message: 'Cena [Kč] musí být vyplněná!' }]}]"
                />
              </a-form-item>
            </a-col>
            <a-col :span="2">
              <a-icon
                v-if="form.getFieldValue('items').length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.getFieldValue('items').length === 1"
                @click="() => removeItem(index)"
              />
            </a-col>
          </a-row>
        </a-form-item>
        <a-form-item v-bind="formItemLayout">
          <a-button
            class="m-auto d-block"
            type="dashed"
            @click="addItem"
            style="width: 200px;"
          >
            <a-icon type="plus" /> Přidat další
          </a-button>
        </a-form-item>

        <a-form-item
          label="Zdarma od celkové ceny objednávky [Kč]"
          :validate-status="error('dhy_free_from_total') ? 'error' : ''"
          :help="error('dhy_free_from_total') || ''"
        >
          <a-input-number
            :min="0.00"
            :step="0.01"
            style="width: 100%;"
            v-decorator="[`dhy_free_from_total`, { initialValue: undefined }]"
          />
        </a-form-item>

        <a-form-item
          label="Zobrazit do celkové váhy objednávky [g]"
          :validate-status="error('dly_show_till_weight') ? 'error' : ''"
          :help="error('dly_show_till_weight') || ''"
        >
          <a-input-number
            :min="0"
            :precision="0"
            :step="1"
            style="width: 100%;"
            v-decorator="[`dly_show_till_weight`, { initialValue: undefined }]"
          />
        </a-form-item>

        <a-form-item
          label="Zobrazit do celkové ceny objednávky [Kč]"
          :validate-status="error('dly_show_till_total') ? 'error' : ''"
          :help="error('dly_show_till_total') || ''"
        >
          <a-input-number
            :min="0.00"
            :step="0.01"
            style="width: 100%;"
            v-decorator="[`dly_show_till_total`, { initialValue: undefined }]"
          />
        </a-form-item>

        <my-form-image-item
          title="Obrázek"
          subject="Doprava"
          decorator-name="image_id"
          :image="thumbImage"
          :image-next-count="thumbImageNextCount"
          :images="thumbImages"
          :on-image-switch-change="onThumbImageSwitchChange"
          :on-next-images-click="onNextThumbImagesClick"
          :form="form"
        ></my-form-image-item>

        <a-form-item
          label="Vyber dopravní službu"
          :validate-status="error('service_id') ? 'error' : ''"
          :help="error('service_id') || ''"
        >
          <a-select :allowClear="true" placeholder="-- Vyber dopravní službu --" :loading="services.loading" :options="deliveryServices.map(x => { return { value: x.id, label: '# ' + x.id + ' | ' + x.description } })" v-decorator="['service_id']"></a-select>
        </a-form-item>

        <a-form-item
          label="Vyber balíkobot službu"
          :validate-status="error('balikobot_service_id') ? 'error' : ''"
          :help="error('balikobot_service_id') || ''"
        >
          <a-select :allowClear="true" placeholder="-- Vyber balíkobot službu --" :loading="balikobotServices.loading" :options="balikobotServicesItems.map(x => { return { value: x.id, label: '# ' + x.id + ' | ' + x.name } })" v-decorator="['balikobot_service_id']"></a-select>
        </a-form-item>

        <div class="form-actions">
          <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError()) || (thumbImage.switch && !this.isThumbImageSelected(false))">Upravit</a-button>
        </div>
      </a-form>
    </div>
  </section>
</template>

<script>
import LanguageTab from '@/components/MyComponents/LanguageTab'
import ActionTools from '@/components/MyComponents/ActionTools'
import { hasErrors } from '../../../../services/forms'
import myFormImageItem from '@/components/MyComponents/Gallery/my-form-image-item'
import thumbImageMixin from '@/components/MyComponents/Gallery/thumb-image-mixin'

export default {
  components: {
    ActionTools, LanguageTab, myFormImageItem,
  },
  mixins: [thumbImageMixin],
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.form.getFieldDecorator('items', { initialValue: [], preserve: true })
  },
  data() {
    return {
      hasErrors,
      id: this.$route.params.id,
      loading: false,
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/ecommerce/delivery',
          title: 'Seznam',
        },
      ],
      services: {
        loading: false,
      },
      balikobotServices: {
        loading: false,
      },
      item: {
        languages: [],
        payments: {
          loading: false,
        },
      },
      formItemLayout: {
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      loaded: [],
    }
  },
  computed: {
    detail: function() {
      return this.$store.getters['delivery/getDetail']
    },
    deliveryServices: function () {
      return this.$store.getters['deliveryServices/allItems']
    },
    balikobotServicesItems: function () {
      return this.$store.getters['balikobotServices/allItems']
    },
    language: function () {
      return this.$store.getters['language/active']
    },
    listHeureka: function () {
      return this.$store.getters['deliveryDictionary/listHeureka']
    },
    listZbozi: function () {
      return this.$store.getters['deliveryDictionary/listZbozi']
    },
    payments: function () {
      return this.$store.getters['payment/currentLanguage']('')
    },
  },
  methods: {
    removeItem(index) {
      const items = this.form.getFieldValue('items')
      if (items.length === 1) {
        return
      }
      const weightMins = this.form.getFieldValue('dwy_weight_min')
      const weightMaxs = this.form.getFieldValue('dwy_weight_max')
      const prices = this.form.getFieldValue('dwy_price')
      items.splice(index, 1)
      weightMins.splice(index, 1)
      weightMaxs.splice(index, 1)
      prices.splice(index, 1)
      this.form.setFieldsValue({
        items: items,
        dwy_weight_min: weightMins,
        dwy_weight_max: weightMaxs,
        dwy_price: prices,
      })
    },
    addItem() {
      const newIndex = this.form.getFieldValue('items').length
      this.form.getFieldDecorator(`dwy_weight_min[${newIndex}]`)
      this.form.getFieldDecorator(`dwy_weight_max[${newIndex}]`)
      this.form.getFieldDecorator(`dwy_price[${newIndex}]`)
      this.form.setFields({
        items: {
          value: this.form.getFieldValue('items').concat({
            dwy_weight_min: 0,
            dwy_weight_max: undefined,
            dwy_price: 0,
          }),
        },
        ['dwy_weight_min[' + newIndex + ']']: { value: 0 },
        ['dwy_weight_max[' + newIndex + ']']: { value: undefined },
        ['dwy_price[' + newIndex + ']']: { value: 0 },
      })
      this.form.validateFields()
    },
    initDetail() {
      this.loading = true
      this.$store.dispatch('delivery/getOne', this.id)
        .then(() => {
          this.item = Object.assign({}, this.detail)
          const currentDetailLang = this.$store.getters['delivery/currentLanguageDetail']
          this.form.getFieldDecorator('image_id')
          this.form.setFieldsValue({
            image_id: this.item.image === null ? null : this.item.image.iae_id,
            service_id: this.item.service_id === null ? undefined : this.item.service_id,
            balikobot_service_id: this.item.balikobot_service_id === null ? undefined : this.item.balikobot_service_id,
            dhe_name: currentDetailLang.dhe_name,
            dly_show_from_weight: this.item.dly_show_from_weight,
            dly_show_from_total: this.item.dly_show_from_total,
            dly_show_till_weight: this.item.dly_show_till_weight,
            dly_show_till_total: this.item.dly_show_till_total,
            dhy_is_sum_weight_price: this.item.current_history.dhy_is_sum_weight_price,
            dly_is_active: this.item.dly_is_active,
            is_pickup: this.item.is_pickup,
            dhy_free_from_total: this.item.current_history.dhy_free_from_total,
            payments: this.detail.payments,
            zbozi_id: this.detail.zbozi_id.toString(),
            heureka_id: this.detail.heureka_id.toString(),
            items: this.detail.current_history.current_weight_histories,
          })
          this.thumbImage.trigger = !this.thumbImage.trigger
          if (this.item.image !== null && this.item.image.iae_id !== null) {
            this.onThumbImageSwitchChange(true)
          }
          this.thumbImage.selectedOne = this.detail.image
          if (this.form.getFieldValue('items')) {
            this.form.getFieldValue('items').forEach((value, key) => {
              this.form.getFieldDecorator(`dwy_weight_min[${key}]`)
              this.form.getFieldDecorator(`dwy_weight_max[${key}]`)
              this.form.getFieldDecorator(`dwy_price[${key}]`)
              this.form.setFields({
                ['dwy_weight_min[' + key + ']']: { value: value.dwy_weight_min },
                ['dwy_weight_max[' + key + ']']: { value: value.dwy_weight_max },
                ['dwy_price[' + key + ']']: { value: value.dwy_price },
              })
            })
          }
          this.form.validateFields()
        })
        .catch(() => {})
        .finally(() => {
          this.loaded.push(this.language)
          this.loading = false
        })
    },
    handleSubmit(e) {
      e.preventDefault()
      this.handleChange(this.language)
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('delivery/put', { id: this.id, item: this.getData() })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    getData() {
      return {
        image_id: this.thumbImage.selectedOne === undefined || this.thumbImage.selectedOne === null ? null : parseInt(this.thumbImage.selectedOne.iae_id),
        dly_show_from_weight: this.form.getFieldValue('dly_show_from_weight'),
        dly_show_from_total: this.form.getFieldValue('dly_show_from_total'),
        service_id: this.form.getFieldValue('service_id') === undefined ? null : this.form.getFieldValue('service_id'),
        balikobot_service_id: this.form.getFieldValue('balikobot_service_id') === undefined ? null : this.form.getFieldValue('balikobot_service_id'),
        dly_show_till_weight: this.form.getFieldValue('dly_show_till_weight') === undefined ? null : this.form.getFieldValue('dly_show_till_weight'),
        dly_show_till_total: this.form.getFieldValue('dly_show_till_total') === undefined ? null : this.form.getFieldValue('dly_show_till_total'),
        dhy_is_sum_weight_price: this.form.getFieldValue('dhy_is_sum_weight_price'),
        dly_is_active: this.form.getFieldValue('dly_is_active'),
        is_pickup: this.form.getFieldValue('is_pickup'),
        dhy_free_from_total: this.form.getFieldValue('dhy_free_from_total') === undefined ? null : this.form.getFieldValue('dhy_free_from_total'),
        weight_histories: this.form.getFieldValue('items').map((x, y) => { return { dwy_price: this.form.getFieldValue('dwy_price[' + y + ']'), dwy_weight_min: this.form.getFieldValue('dwy_weight_min[' + y + ']'), dwy_weight_max: this.form.getFieldValue('dwy_weight_max[' + y + ']') === undefined ? null : this.form.getFieldValue('dwy_weight_max[' + y + ']') } }),
        payments: this.form.getFieldValue('payments').map(x => { return { pmt_id: x } }),
        zbozi_id: this.form.getFieldValue('zbozi_id'),
        heureka_id: this.form.getFieldValue('heureka_id'),
        languages: this.item.current_history.languages.filter(x => x.dhe_name !== undefined && x.dhe_name.trim().length > 0).map(x => {
          return {
            lge_id: x.lge_id,
            dhe_name: x.dhe_name,
          }
        }),
      }
    },
    handleChange(oldLanguage) {
      const foundObj = { delivery: false }
      const newObj = {
        delivery: {
          lge_id: oldLanguage,
          dhe_name: this.form.getFieldValue('dhe_name'),
        },
      }
      this.item.current_history.languages.some((value, key) => {
        if (value.lge_id === oldLanguage) {
          this.item.current_history.languages[key] = newObj.delivery
          foundObj.delivery = true
          return true
        }
      })
      if (!foundObj.delivery) {
        this.item.current_history.languages.push(newObj.delivery)
      }
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    initData() {
      this.item.payments.loading = true
      this.services.loading = true
      Promise.all([
        this.$store.dispatch('deliveryServices/list'),
        this.$store.dispatch('payment/getList'),
        this.$store.dispatch('balikobotServices/getList'),
        this.$store.dispatch('deliveryDictionary/listHeureka'),
        this.$store.dispatch('deliveryDictionary/listZbozi'),
      ])
        .then(() => {
          this.initDetail()
        })
        .catch(() => {})
        .finally(() => {
          this.item.payments.loading = false
          this.services.loading = false
          this.loaded.push(this.language)
        })
    },
    refreshComponent(id) {
      this.id = id
      this.services.loading = false
      this.item = {
        languages: [],
        payments: {
          loading: false,
        },
      }
      this.refreshThumbImage()
      this.loaded = []
    },
  },
  created() {
    if (this.language !== null && !this.loaded.includes(this.language)) {
      this.initData()
    }
  },
  watch: {
    language(newValue, oldValue) {
      if (oldValue !== null && this.loaded.includes(oldValue)) {
        this.handleChange(oldValue)
      }
      if (newValue !== null) {
        if (!this.loaded.includes(this.language)) {
          this.initData()
        } else {
          const foundObj = {
            delivery: this.item.current_history.languages.find(x => x.lge_id === newValue),
          }
          const newObj = foundObj.delivery === undefined ? { dhe_name: undefined } : { dhe_name: foundObj.delivery.dhe_name }
          this.form.setFieldsValue(newObj)
          if (!this.loaded.includes(this.language)) {
            this.initDetail()
          }
        }
        this.form.validateFields()
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initDetail()
      }
    },
  },
}
</script>

<style lang="scss" module>
  @import "./style.module.scss";
</style>
